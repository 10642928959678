import { Theme } from "src/environments";

export const theme = {
  theme: "Smart Energy Solutions" as Theme,

  uiTitle: "Smart Energy Solutions Kundenportal",
  edgeShortName: "SES EMS",
  edgeLongName: "Smart Energy Solutions Energiemanagementsystem",

  docsUrlPrefix: "https://ses-systeme.de/",
  links: {
    COMMON_STORAGE: null,
    FORGET_PASSWORD: "https://erp.ses-systeme.de/web/reset_password",
    EVCS_HARDY_BARTH: null,
    EVCS_KEBA_KECONTACT: null,
    EVCS_OCPP_IESKEYWATTSINGLE: null,

    CONTROLLER_ESS_GRID_OPTIMIZED_CHARGE: null,
    CONTROLLER_CHP_SOC: null,
    CONTROLLER_IO_CHANNEL_SINGLE_THRESHOLD: null,
    CONTROLLER_IO_FIX_DIGITAL_OUTPUT: null,
    CONTROLLER_IO_HEAT_PUMP_SG_READY: null,
    CONTROLLER_IO_HEATING_ELEMENT: null,
    CONTROLLER_ESS_TIME_OF_USE_TARIFF: null,

    CONTROLLER_API_MODBUSTCP_READ: null,
    CONTROLLER_API_MODBUSTCP_READWRITE: null,

    CONTROLLER_API_REST_READ: null,
    CONTROLLER_API_REST_READWRITE: null,

    SETTINGS_ALERTING: null,
    SETTINGS_NETWORK_CONFIGURATION: null,
    EVCS_CLUSTER: null,

    WARRANTY: {
      HOME: {
        EN: "#",
        DE: "#",
      },
      COMMERCIAL: {
        EN: "#",
        DE: "#",
      },
    },

    GTC: {
      EN: "#",
      DE: "#",
    },

    METER: {
      SOCOMEC: "#",
      KDK: "#",
    },

    MANUALS: {
      HOME: {
        HOME_10: "#",
        HOME_20_30: "#",
      },
      COMMERCIAL: {
        COMMERCIAL_30: "#",
        COMMERCIAL_50: "#",
      },
    },
    APP_CENTER: {
      APP_IMAGE: (language: string, appId: string): string | null => {
        return null;
      },
    },
    APP: {
      ANDROID: null,
      IOS: null,
    },
  },
  PRODUCT_TYPES: () => null,
};
