import { Component } from "@angular/core";
import { AbstractFlatWidget } from "src/app/shared/components/flat/abstract-flat-widget";
import { Utils } from "src/app/shared/shared";
import * as converters from "../converters";
import { ElectrolyserModalComponent } from "../modal/modal.component";

@Component({
  selector: "electrolyser",
  templateUrl: "./flat.component.html",
})
export class ElectrolyserFlatComponent extends AbstractFlatWidget {
  public readonly CONVERT_WATT_TO_KILOWATT = Utils.CONVERT_WATT_TO_KILOWATT;
  public readonly CONVERT_ELECTROLYSER_STATE = converters.CONVERT_ELECTROLYSER_STATE(this.translate);
  public readonly CONVERT_TO_LITERS_PER_HOUR = converters.CONVERT_TO_LITERS_PER_HOUR;

  async presentModal() {
    if (!this.isInitialized) {
      return;
    }
    const modal = await this.modalController.create({
      component: ElectrolyserModalComponent,
      componentProps: {
        component: this.component,
      },
    });
    return await modal.present();
  }
}
