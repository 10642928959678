import { Environment } from "src/environments";
import { theme } from "./theme";

export const environment: Environment = {
    ...theme, ...{

        backend: "OpenEMS Backend",
        url: "wss://portal.ses-systeme.de/ui-to-backend",

        production: true,
        debugMode: false,
    },
};
