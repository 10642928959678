import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { SharedModule } from "src/app/shared/shared.module";
import { ElectrolyserFlatComponent } from "./flat/flat.component";
import { ElectrolyserModalComponent } from "./modal/modal.component";

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
  ],
  declarations: [
    ElectrolyserFlatComponent,
    ElectrolyserModalComponent,
  ],
  exports: [
    ElectrolyserFlatComponent,
  ],
})
export class ElectrolyserModule { }
