import { formatNumber } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { formatDuration, intervalToDuration } from "date-fns";
import { de } from "date-fns/locale";

export const CONVERT_ELECTROLYSER_STATE = (translate: TranslateService) => {
  return (value: number | null): string => {
    switch (value) {
      case 0:
        return translate.instant("Edge.Index.Widgets.ELECTROLYSER.STATE.HALTED");
      case 1:
        return translate.instant("Edge.Index.Widgets.ELECTROLYSER.STATE.MAINTENANCE_MODE");
      case 2:
        return translate.instant("Edge.Index.Widgets.ELECTROLYSER.STATE.IDLE");
      case 3:
        return translate.instant("Edge.Index.Widgets.ELECTROLYSER.STATE.STEADY");
      case 4:
        return translate.instant("Edge.Index.Widgets.ELECTROLYSER.STATE.STAND_BY");
      case 5:
        return translate.instant("Edge.Index.Widgets.ELECTROLYSER.STATE.CURVE");
      default:
        return "-";
    }
  };
};

export const CONVERT_SECONDS_TO_DURATION_FORMAT = (locale: string) => {
  return (value: number | null): string => {
    if (value == null) {
      return "-";
    }

    const duration = intervalToDuration({ start: 0, end: value * 1000 });
    return formatDuration(duration, { ...(locale === "de" ? { locale: de } : {}) });
  };
};

const CONVERT_TO_UNIT = (unit: string) => {
  return (value: number | null): string => {
    if (value == null) {
      return "-";
    }

    return formatNumber(value, "de", "1.0-1") + " " + unit;
  };
};

export const CONVERT_TO_LITERS = CONVERT_TO_UNIT("NL");
export const CONVERT_TO_LITERS_PER_HOUR = CONVERT_TO_UNIT("NL/h");
export const CONVERT_TO_BAR = CONVERT_TO_UNIT("bar");
export const CONVERT_TO_AMPERE = CONVERT_TO_UNIT("A");
export const CONVERT_TO_VOLT = CONVERT_TO_UNIT("V");
export const CONVERT_TO_DEGREE_CELSIUS = CONVERT_TO_UNIT("°C");
